@import url('https://rsms.me/inter/inter.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Inter var', system-ui, sans-serif;
    -webkit-font-smoothing: antialiased;
  }
  
  body {
    @apply bg-white;
  }
}

@layer components {
  .timeline-container {
    @apply relative pl-8 before:content-[''] before:absolute before:left-[11px] 
           before:top-0 before:h-full before:w-[1px] before:bg-gray-200;
  }
  
  .timeline-dot {
    @apply absolute left-0 w-6 h-6 rounded-full bg-white 
           border border-gray-200 flex items-center justify-center
           -translate-x-[9px] z-10;
  }
  
  .message-card {
    @apply bg-white p-3 ml-2
           transition-all duration-200;
  }
  
  .tag {
    @apply px-2.5 py-0.5 text-xs font-medium rounded-full;
  }
  
  .tag-insight {
    @apply bg-purple-50 text-purple-700;
  }
  
  .tag-risk {
    @apply bg-red-50 text-red-700;
  }
  
  .tag-question {
    @apply bg-blue-50 text-blue-700;
  }
  
  .tag-opportunity {
    @apply bg-green-50 text-green-700;
  }
}

/* Animation keyframes */
@keyframes fadeIn {
  from { 
    opacity: 0; 
    transform: translateY(8px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-out forwards;
}
