@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@600;700;800&display=swap');

/* Core layout */
[data-component='ConsolePage'] {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
  max-height: 100vh;
  padding: 20px;
  gap: 20px;
  background: #FAFAFA;
  overflow: hidden;
  box-sizing: border-box;
}

.content-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 24px;
  background: white;
  border-radius: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border: 1px solid #F0F0F0;
}

.content-left {
  display: flex;
  align-items: center;
  gap: 24px;
}

.content-title {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-right: auto;
}

.content-main {
  min-height: 0;
  height: 100%;
}

.content-transcript {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
  border: 1px solid #F0F0F0;
}

.content-block-header {
  flex-shrink: 0;
  padding: 20px 24px;
  border-bottom: 1px solid #F0F0F0;
}

.content-block-title {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #18181B;
}

/* Message styling */
.conversation {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.content-block-body {
  flex: 1;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 16px;
}

.content-block-body::-webkit-scrollbar {
  display: none;
}

.conversation-item {
  padding: 4px 12px;
  position: relative;
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.conversation-item[data-role="user"] {
  align-items: flex-start;
}

.conversation-item[data-role="user"] .message-content {
  font-family: 'Inter', sans-serif;
  border-radius: 16px 16px 16px 4px;
  background: #f8f9fa;
  max-width: 65%;
  margin-left: 0;
  padding: 12px 16px;
  color: #1a1a1a;
  font-size: 14px;
  line-height: 1.5;
}

.conversation-item[data-role="assistant"] {
  align-items: flex-end;
}

.conversation-item[data-role="assistant"] .message-content {
  font-family: 'Inter', sans-serif;
  border-radius: 16px 16px 4px 16px;
  background: #f0f4ff;
  max-width: 65%;
  margin-right: 0;
  padding: 12px 16px;
  color: #1a1a1a;
  font-size: 14px;
  line-height: 1.5;
}

/* Recording indicator */
.recording-indicator {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.recording-indicator::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #EF4444;
  animation: pulse-dot 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .content-transcript {
    border-radius: 16px;
  }
  
  .conversation-item {
    padding: 8px 16px;
  }
}

.content-title span {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
}
